<template>
  <div v-if="checkPermission('withdraw', 'action', 'affiliate')">
    <div class="ml-auto button-withdraw flex-center" :class="`${totalAmount < 1000 ? 'disabled' : ''}`"
      @click="showModalWithdraw">
      <img v-if="totalAmount < 1000" src="@/assets/images/affiliate/withdrawal_btn_disable.svg" />
      <img v-else src="@/assets/images/affiliate/withdrawal_btn.svg" />
      <div class="ml-auto">
        {{ $t('withdrawal.withdrawal_btn') }}
      </div>
    </div>
    <b-modal id="withdrawal_modal" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>{{ $t('withdrawal.credit_withdrawal') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="cancelWithdrawal('withdrawal')"></i>
      </template>
      <div class="pl-2 pr-2">
        <h2 class="font-size-18 mt-2">{{ $t('withdrawal.credit_can_withdraw') }}</h2>
        <h2 class="text-green">{{ addCommaAndPrice(totalAmount) }}</h2>
        <input-component v-model="$v.form.value.$model" :label-input="$t('withdrawal.withdrawal_value')"
          :state="validateState($v.form.value)" required :require-label="showLabelValidation()" @input="checkZero" 
          @keyup="updateTextView" @keypress="checkNumber" max="11"  />
        <b-form-checkbox id="checkbox-1" v-model="hastVat" v-if="userInfo.is_juristic && !(userInfo.vat_cert)" name="checkbox-1">
          {{ $t('withdrawal.vat_attach_file') }}
        </b-form-checkbox>
        <div v-if="hastVat">
          <upload-file-signature-component @input="onUploadFile" accept="image/png, image/jpeg, .pdf"
            :info-content="$t('account.verify_account.upload_id_card_file_info_cintent')"
            :text-upload-content="$t('account.verify_account.company_cert_upload')" required />
        </div>
        <div class="pt-3" style="color: var(--danger);">
          <div>{{ $t('withdrawal.withdrawal_condition.condition') }}</div>
          <ul>
            <li>{{ $t('withdrawal.withdrawal_condition.li_1') }}</li>
            <li>{{ $t('withdrawal.withdrawal_condition.li_2') }}</li>
            <li>{{ $t('withdrawal.withdrawal_condition.li_3') }}</li>
            <li>{{ $t('withdrawal.withdrawal_condition.li_4') }}</li>
            <li>{{ $t('withdrawal.withdrawal_condition.li_5') }}</li>
            <li>{{ $t('withdrawal.withdrawal_condition.li_6') }}</li>
          </ul>
        </div> 
        <div class="flex-center mt-4 mb-3">
          <button type="button" class="btn waves-effect waves-light btn-outline ml-auto"
            @click="cancelWithdrawal('withdrawal')">
            {{ $t('common.cancel') }}
          </button>
          <button :disabled="form.value && form.value < 1000" type="button"
            class="btn waves-effect waves-light btn-gradient ml-2" @click="submitValue()">
            {{ $t('common.confirm') }}
          </button>
        </div>
      </div>
    </b-modal>
    <!-- Details -->
    <b-modal id="withdrawal_details_modal" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>{{ $t('withdrawal.summary_withdrawal') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="cancelWithdrawal('withdrawal_details')"></i>
      </template>
      <div class="">
        <b-row>
          <b-col class="label">
            {{ $t('withdrawal.date_withdrawal') }}
          </b-col>
          <b-col align="end">
            {{ changeDateFormat(previewDetails?.withdraw_date) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              <span v-if="previewDetails?.customer_type === 'personal'">
                 {{ $t('account.verify_account.first_name') }}/{{ $t('account.verify_account.last_name') }}
              </span>
              <span v-else>
                {{ $t('account.verify_account.company_name') }}
              </span>
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.name }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('account.verify_account.tax_id') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.tax_id }}
          </b-col>
        </b-row>
        <b-row v-if="previewDetails?.customer_type === 'juristic'" class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('account.verify_account.company_phone') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.company_phone }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.phone') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.phone }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.email') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.email }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.address') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ changeFormatAddress(previewDetails?.address) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.bank_method') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.account_name }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.account_number') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.account_no }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.bank') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.bank }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.value_of_withdrawal') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #161E54;">
            {{ addCommaAndPrice(form.value) }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.vat') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #5C698C;">
            +{{ addCommaAndPrice(previewDetails?.vat_7_percent) }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              <span v-if="previewDetails?.customer_type == 'personal'">
                {{ $t('subscription.checkout.with_holding_tax').replace('3', '5') }}
              </span>
              <span v-else>
                {{ $t('subscription.checkout.with_holding_tax') }}
              </span>
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #D30000;">
            <span v-if="previewDetails?.customer_type == 'personal'">
              -{{ addCommaAndPrice(previewDetails?.withholding_tax_5_percent) }}
            </span>
            <span v-else>
              -{{ addCommaAndPrice(previewDetails?.withholding_tax_3_percent) }}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.summary_withdrawal_transfer') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #00927F;">
            {{ addCommaAndPrice(previewDetails?.net_balance) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <div class="label mt-2">{{ $t('withdrawal.summary_withdrawal_result') }}</div>
            <div style="font-size: 36px; font-weight: 700; color: #00927F;">
              {{ addCommaAndPrice(previewDetails?.net_balance) }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <div class="flex-center mt-4 mb-3">
          <button type="button" class="btn waves-effect waves-light btn-outline ml-auto" @click="backToFillValue">
            {{ $t('common.cancel') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-gradient ml-2" @click="confirmDetails()">
            {{ $t('common.confirm') }}
          </button>
        </div>
      </div>
    </b-modal>
    <!-- Fill password -->
    <b-modal id="fill_password_modal" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>{{ $t('withdrawal.credit_withdrawal') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="cancelWithdrawal('fill_password')"></i>
      </template>
      <div>
        <div class="mt-4">
          <input-component type="password" icon="password" :placeholder="$t('common.enter_password')"
            v-model="$v.confirm.password.$model" @keyup.enter="confirmProcess" />
        </div>
        <div class="flex-center mt-1 mb-2">
          <button type="button" class="btn waves-effect waves-light btn-outline ml-auto" @click="backToDetails()">
            {{ $t('common.back') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-gradient ml-2" @click="confirmProcess()" :disabled="loadingCallAPI">
            {{ $t('common.confirm') }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import PreviewImgOrPdfComponent from "@/components/common/previewImgOrPdfComponent.vue";
import UploadFileSignatureComponent from "@/components/common/uploadFileSignatureComponent.vue";
import InputComponent from "@/components/common/inputComponent";
import functions from "@/common/functions";
import { required, minLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import affiliateApi from "@/repository/affiliateApi";
import alert from "@/common/alert";
import profileApi from "@/repository/profileApi";
import moment from "moment";

export default {
  components: {
    UploadFileSignatureComponent,
    InputComponent
  },
  props: {
    totalAmount: {
      type: Number,
      default: 0
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      response_created: {
        title: '',
        short_link: ''
      },
      hastVat: false,
      files: null,
      form: {
        value: ''
      },
      confirm: {
        password: ''
      },
      previewDetails: {
        address: {
          address: '',
          sub_district: '',
          district: '',
          province: '',
          zipcode: ''
        },
        amount: 0,
        brand_id: "",
        codeSYS: "",
        customer_bank: {
          account_name: '',
          account_no: '',
          bank: '',
          book_bank: ''
        },
        customer_id: "",
        email: "",
        group_id: "",
        member_id: "",
        name: "",
        net_balance: 0,
        phone: "",
        tax_id: "",
        transaction_period: "",
        vat_7_percent: 70,
        withdraw_date: "",
        withholding_tax_3_percent: 30
      },
      loadingCallAPI: false,
    }
  },
  validations: {
    form: {
      value: {
        required,
        minLength: minLength(4),
      },
    },
    confirm: {
      password: {
        required,
        minLength: minLength(1),
      }
    }
  },
  watch: {
    form: {
      handler(item) {
        if (item.value && item.value[0] === '0') {
          item.value = item.value.slice(1)
        }
      },
      deep: true
    },
    'confirm.password'(newVal) {
      if (newVal) {
        this.errLabel = null
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    }
  },
  methods: {
    checkFormatFuncOnlyNumber(_value) {
      const regex = /[^0-9]/g
      const isNumber = _value.replace(regex, '')
      return isNumber ? parseInt(isNumber) : 0
    },
    changeDateFormat(date) {
      return date ? moment(date).format("DD/MM/YYYY HH:mm") : "";
    },
    addCommaAndPrice(data) {
      return `฿${data ? data.toLocaleString() : 0}`
    },
    confirmProcess() {
      this.loadingCallAPI = true;
      if (this.$v.confirm.$invalid) {
        this.$v.$touch();
        this.loadingCallAPI = false;
        return;
      }
      this.confirmPassword()
    },
    checkNumber(e) {
      return functions.isOnlyNumBerAndDecimal(e)
    },
    updateTextView(event) {
      let num = this.checkFormatFuncOnlyNumber(event.target._value);
      let value = 100000000
      if (num > 0 && num <= value) {
        this.form.value = num.toLocaleString();
      } else if (num > value) {
        this.form.value = value?.toLocaleString();
      } else {
        this.form.value = num;
      }
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    checkZero() {
      if (this.form.value.charAt(0) === '0') {
        this.form.value = this.form.value.substring(1)
      }
    },
    changeFormatAddress(data) {
      const address = data?.address ? data?.address : ''
      const sub_district = data?.sub_district ? 'แขวง' + data?.sub_district : ''
      const district = data?.district ? 'เขต' + data?.district : ''
      const province = data?.province ? data?.province : ''
      const zipcode = data?.zipcode ? data?.zipcode : ''
      return address + ' ' + sub_district + ' ' + district + ' ' + province + ' ' + zipcode
    },
    confirmPassword() {
      profileApi.checkComparePassword(this.confirm.password).then((res) => {
        if (res.is_compare) {
          this.withdrawProcess()
        } else {
          alert.fail(this, this.$t('withdrawal.fail_password'));
        }
      }).catch((err) => {
        alert.fail(this, err.message)
      }).finally(() => {
        this.loadingCallAPI = false;
      })
    },
    withdrawProcess() {
      const value = this.form.value ? parseInt(this.form.value.replace(/,/g, '')) : 0;
      const data = {
        amount: value,
        vat_cert: this.files || this.userInfo.vat_cert || ""
      }
      affiliateApi.createRequest(data).then((res) => {
        this.$swal.fire({
          icon: 'success',
          title: this.$t('withdrawal.withdrawal_success'),
          showConfirmButton: false,
          timer: 1500
        })
        this.closeModalFillPassword();
        this.$emit('recallSummary')
      }).catch((err) => {
        if (err.errors && err.errors.code === "004") {
          this.$emit('recallSummary')
        }
        alert.fail(this, err.message)
      })
    },
    backToFillValue() {
      this.$bvModal.hide('withdrawal_details_modal');
      this.$bvModal.show('withdrawal_modal');
    },
    submitValue() {
      if (this.$v.form.$invalid) {
        this.$v.$touch();
        return;
      }
      const value = this.form.value ? parseInt(this.form.value.replace(/,/g, '')) : 0;
      const data = {
        _id: this.userInfo._id,
        vat_cert: this.files || this.userInfo.vat_cert || "",
        amount: value
      }
      affiliateApi.reviewRequest(data).then((res) => {
        this.previewDetails = res
        this.$bvModal.hide('withdrawal_modal');
        this.$bvModal.show('withdrawal_details_modal');
      }).catch((err) => {
        alert.fail(this, err.message)
      })
    },
    showModalWithdraw() {
      if (this.totalAmount < 1000) return;
      this.$v.form.$reset();
      this.form.value = '';
      this.$bvModal.show('withdrawal_modal');
    },
    closeModalWithdraw() {
      this.$bvModal.hide('withdrawal_modal');
      this.form.value = '';
    },
    closeModalDetails() {
      this.form.value = '';
      this.$bvModal.hide('withdrawal_details_modal');
    },
    confirmDetails() {
      this.$bvModal.hide('withdrawal_details_modal');
      this.$bvModal.show('fill_password_modal');
    },
    closeModalFillPassword() {
      this.$bvModal.hide('fill_password_modal');
      this.confirm.password = '';
    },
    onUploadFile(value) {
      this.files = value
    },
    validateState(validation) {
      const { $dirty, $error } = validation;
      return $dirty ? !$error : null;
    },
    checkMoreThanMin() {
      const value = this.form.value ? parseInt(this.form.value) : 0;
      return value >= 1000 && value <= this.totalAmount;
    },
    backToDetails() {
      this.closeModalFillPassword();
      this.$bvModal.show('withdrawal_details_modal');
    },
    cancelWithdrawal(step) {
      if (step === 'withdrawal' && this.form.value === '') {
        this.closeModalWithdraw();
        return;
      }

      this.$swal.fire({
        icon: 'warning',
        title: this.$t('withdrawal.confirm_for_cancel_withdrawal'),
        showCancelButton: true,
        confirmButtonColor: '#19CBAC',
        cancelButtonColor: '#ccc',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
        allowOutsideClick: false,
        width: '30em',
        padding: '1.25rem'
      }).then((result) => {
        if (result.isConfirmed) {
          if (step === 'withdrawal') {
            this.closeModalWithdraw();
          } else if (step === 'withdrawal_details') {
            this.closeModalDetails();
          } else if (step === 'fill_password') {
            this.closeModalFillPassword();
          }
        }
      })
    },
    showLabelValidation() {
      if (this.validateState(this.$v.form.value) === false) {
        return this.$t('withdrawal.have_more_than_1000');
      } else {
        return '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes backgroundMove {
  0% {
    background-position: 0% 50%;
  }

  25% {
    background-position: 0% 50%;
  }

  60% {
    background-position: 30% 50%;
  }

  95% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.button-withdraw {
  border-radius: 18px;
  padding: 8px 16px;
  font-size: 20px;
  font-weight: 700;
  width: 210px;
  height: 94px;
  box-shadow: 6px 6px 14px #00000040;
  transition: 0.3s;

  background-size: 140% 140%;
  animation: backgroundMove 6s infinite;

  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(120deg, #7A4801 0%, #E2C279 45%, #E2C279 48%, #9C7009 100%);
  overflow: hidden;
  position: relative;

  &.disabled {
    cursor: not-allowed;
    color: #CDCBCB;
    background-image: linear-gradient(135deg, #fff 0%, #939393 140%);
    box-shadow: 3px 3px 12px #0000002b;

    >img {
      top: -16px !important;
    }
  }

  >img {
    height: calc(100% + (8px * 2) + 16px);
    position: absolute;
    left: 16px;
    transition: 0.5s;
    top: -16px;
  }

  &:hover {
    box-shadow: 3px 3px 12px #0000002b;
    filter: brightness(1.1);

    >img {
      top: 0;
    }
  }
}

.line-dotted {
  height: 0;
  width: 100%;
  border-top: 1px dotted #C5D1DE;
}

.label {
  font-size: 14px;
  color: #5C698C;
  font-weight: 700;

}
</style>

<style lang="scss">
#fill_password_modal,
#withdrawal_details_modal,
#withdrawal_modal {
  h5 {
    margin-bottom: 0;
  }

  .modal-header {
    border-bottom: 1px solid #E9ECEF;
  }
}
</style>